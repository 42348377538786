// src/components/Header.js
import React from 'react';
import './Header.css'; // Ensure this CSS file exists and is properly linked

const Header = () => {
    return (
        <header className="header-transparent">
            <div>
                <h1>$DEAL</h1>
            </div>
            <div className="icon-container">
                {/* <a href="https://t.me/" target="_blank" rel="noopener noreferrer">
                    <img src="https://static-00.iconduck.com/assets.00/telegram-icon-2048x1725-i4kw83ca.png" alt="Telegram" className="icon" />
                </a> */}
                <a href="https://x.com/dealcatcoin" target="_blank" rel="noopener noreferrer">
                    <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-black-icon.png" alt="Twitter" className="icon" />
                </a>
                <a href="https://pump.fun/FtPZcPcvoYuhy1zcJndfE8hURUB47SJV91oTLhWpump" target="_blank" rel="noopener noreferrer">
                    <img src="https://www.pump.fun/_next/image?url=%2Flogo.png&w=64&q=75" alt="Pump.fun" className="icon" />
                </a>
            </div>
        </header>
    );
};

export default Header;