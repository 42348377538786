import React from 'react';
import './MainContent.css';

const MainContent = () => {
  return (
    <div className="main-content">
      <div className="emoji-background">
        {[...Array(150)].map((_, index) => (
          <span key={index} className="emoji">🤝</span>
        ))}
      </div>
      <img src={`${process.env.PUBLIC_URL}/deal.png`} alt="Square Picture" className="square-picture" />

      <p>Supply: 1B</p>
      <p>Liquidity Burned ( pump.fun )</p>
      <p>Tax 0%</p>
      <p>CA: FtPZcPcvoYuhy1zcJndfE8hURUB47SJV91oTLhWpump</p>      <p className="deal-description">
        Two longtime buddies lock in the deal of a lifetime with a firm handshake 🤝 in their go-to coffee spot ☕. These savvy hustlers 🤑 clearly know how to make moves together. The stakes? Who knows—maybe a killer startup idea 💡 or a new venture into uncharted markets 🚀. Their grins 😁 say it all: this partnership’s about to shake up the local scene 🏙️ and could totally change their careers. It’s proof that mixing friendship with ambition is a game-changer 🐾.
      </p>
      <div className="marquee-container">
        <hr />
        <marquee>$DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL $DEAL</marquee>
      </div>
    </div>
  );
};

export default MainContent;